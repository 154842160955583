import $ from 'jquery';

class GoogleService {
  loadSdk() {
    if (this.loadPromise) {
      return this.loadPromise;
    }

    const { clientId } = window.QB.google;

    this.loadPromise = new Promise((resolve, reject) => {
      const failTimeout = setTimeout(reject, 30000);

      window.googleInit = () => {
        clearTimeout(failTimeout);
        const { gapi } = window;

        gapi.load('auth2', () => {
          gapi.auth2.init({
            client_id: clientId,
          });

          resolve(gapi);
        });
      };

      $.getScript('https://apis.google.com/js/platform.js?onload=googleInit');
    });

    return this.loadPromise;
  }
}

export default new GoogleService();
