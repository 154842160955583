import $ from 'jquery';

class FacebookService {
  loadSdk() {
    if (this.loadPromise) {
      return this.loadPromise;
    }

    const { isJsDebug, appId, version } = window.QB.facebook;
    const sdkPath = isJsDebug ? 'sdk/debug' : 'sdk';

    this.loadPromise = new Promise((resolve, reject) => {
      const failTimeout = setTimeout(reject, 30000);

      window.fbAsyncInit = () => {
        clearTimeout(failTimeout);
        const { FB } = window;

        FB.init({
          appId,
          cookie: true,
          xfbml: true,
          version,
        });

        FB.Event.subscribe('xfbml.render', () => {
          $(document).trigger('facebook.rendered');
        });

        resolve(FB);
      };

      $.getScript(`//connect.facebook.net/pt_BR/${sdkPath}.js`);
    });

    return this.loadPromise;
  }
}

export default new FacebookService();
