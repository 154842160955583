import $ from 'jquery';

class AppleService {
  loadSdk() {
    if (this.loadPromise) {
      return this.loadPromise;
    }

    this.loadPromise = new Promise((resolve, reject) => {
      const failTimeout = setTimeout(reject, 30000);

      $.getScript('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', () => {
        clearTimeout(failTimeout);
        const { AppleID } = window;
        resolve(AppleID);
      });
    });

    return this.loadPromise;
  }
}

export default new AppleService();
