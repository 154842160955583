import $ from 'jquery';

let loadingTimeRemaining = 8200;

const sleep = timeoutInMilliseconds => new Promise((resolve) => {
  setTimeout(() => {
    loadingTimeRemaining -= timeoutInMilliseconds;
    resolve();
  }, timeoutInMilliseconds);
});

const configureSkipCode = () => {
  const pressed = [];
  const secretCode = 'sk';

  const exec = (e) => {
    pressed.push(e.key);

    if (pressed.join('').includes(secretCode)) {
      $('.js-loading-pre-checkout').addClass('loading-pre-checkout--is-closed');
      window.removeEventListener('keyup', exec);
    }
  };

  window.addEventListener('keyup', exec);
  setTimeout(() => window.removeEventListener('keyup', exec), loadingTimeRemaining);
};

$(async () => {
  const $loadingPreCheckout = $('.js-loading-pre-checkout');

  // Prevent javacript execution when has no loading
  if ($loadingPreCheckout.length === 0) return;

  $.cookieManager.createCookie('viewed_loading_pre_checkout_v2', 'true', 1);
  configureSkipCode();

  await sleep(500);
  $('.js-todo-list').addClass('loading-pre-checkout__list--is-open');

  if (window.QB.admissionLoading) {
    // show admission loading only once
    $.cookieManager.createCookie('viewed_loading_admission', 'true', 1);
    loadingTimeRemaining = 4700;

    $('.js-choose-course').addClass('loading-pre-checkout__item--was-done');
    $('.js-choose-best-offer').addClass('loading-pre-checkout__item--was-done');
    $('.js-register-student').addClass('loading-pre-checkout__item--is-doing');

    await sleep(1500);
    $('.js-register-student').removeClass('loading-pre-checkout__item--is-doing');
    $('.js-register-student').addClass('loading-pre-checkout__item--was-done');

    await sleep(1500);
    $('.js-digital-admission').addClass('loading-pre-checkout__item--is-doing');
  } else {
    await sleep(1500);
    $('.js-choose-course').addClass('loading-pre-checkout__item--was-done');

    await sleep(1500);
    $('.js-choose-best-offer').addClass('loading-pre-checkout__item--was-done');

    await sleep(1500);
    $('.js-register-student').addClass('loading-pre-checkout__item--is-doing');
  }

  await sleep(loadingTimeRemaining);
  $loadingPreCheckout.addClass('loading-pre-checkout--is-closed');
});
