import testCPF from 'utils/test_cpf';

export const validatorRegex = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^\(([14689][1-9]|[23][12478]|[357][1345]|77|79)\) (9\d{4}|[2-9]\d{3})-?\d{4}$/,
  name: /^[A-zÀ-ú]+\s[A-zÀ-ú]+/,
  youtubeUrl: /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/,
  date: /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/([0-9]{4})$/,
  postalCode: /^\d{2}.\d{3}-\d{3}?$|^\d{5}-?\d{3}?$/,
  enemNumber: /^\d{3}.\d{3}.\d{3}\.\d{3}$/,
};

class Validator {
  static isBirthdayValid(birthday) {
    const today = new Date();
    const currentYear = today.getFullYear();
    const userYear = parseInt(birthday.split('/')[2], 10);
    const isValidDate = /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/([0-9]{4})$/.test(birthday);

    return isValidDate && userYear < currentYear;
  }

  static isEmailValid(email) {
    return validatorRegex.email.test(email);
  }

  static isPhoneValid(number) {
    return validatorRegex.phone.test(number);
  }

  static isNameValid(name) {
    return validatorRegex.name.test(name);
  }

  static isYoutubeUrlValid(url) {
    return validatorRegex.youtubeUrl.test(url);
  }

  static isDateValid(date) {
    return validatorRegex.date.test(date);
  }

  static isCpfValid(cpf) {
    return testCPF(cpf);
  }

  static isAgeValid(age) {
    return age >= 16;
  }

  static isPostalCodeValid(postalCode) {
    return validatorRegex.postalCode.test(postalCode);
  }

  static validate(field, value, bypass = false) {
    switch (field) {
      case 'birthday': return this.isBirthdayValid(value);
      case 'email': return this.isEmailValid(value);
      case 'phone': return this.isPhoneValid(value);
      case 'name': return this.isNameValid(value);
      case 'youtubeUrl': return this.isYoutubeUrlValid(value);
      case 'cpf': return this.isCpfValid(value);
      case 'date': return this.isDateValid(value);
      case 'age': return bypass === true ? bypass : this.isAgeValid(value);
      case 'postal_code': return this.isPostalCodeValid(value);
      default: return false;
    }
  }
}

export default Validator;
