import $ from 'jquery';
import ScreenUtils from 'application/screen_utils';
import 'bootstrap/js/modal';
import 'metrics';

class Menu {
  constructor(guia = false) {
    this.$menu = $('.js-user-menu');
    this.$menuContainer = $('.js-user-menu-container');
    this.guia = guia;
    this.menuWidth = 180;
  }

  showMenu() {
    if (this.$menu.length && !this.$menu.hasClass('open')) {
      this.$menu.addClass('open');

      if (ScreenUtils.smOrSmaller()) {
        const menuHeight = $(window).height();

        this.$menu
          .removeClass('hide')
          .css({
            height: menuHeight,
            marginRight: `-${this.menuWidth}px`,
            maxHeight: menuHeight - this.$menu.offset().top,
            width: this.menuWidth,
          })
          .animate({
            marginRight: 0,
          }, 200);

        $('body')
          .bind('touchmove', event => event.preventDefault())
          .addClass('stop-scrolling');
      } else {
        this.$menuContainer.popover('show');
      }

      setTimeout(() => {
        $(window).one('click', this.hideMenu.bind(this));
      }, 250);
    }

    $('.js-queropago-menu-link').off('click');
    $('.js-queropago-menu-link').on('click', () => {
      trackable.eventTracker.track(Metrics.Events.ClickedUserMenuQueroPagoLink, { path: document.location.pathname });
    });
  }

  hideMenu() {
    if (this.$menu.length && this.$menu.hasClass('open')) {
      this.$menu.removeClass('open');

      if (ScreenUtils.smOrSmaller()) {
        this.$menu.animate({
          marginRight: `-${this.menuWidth}px`,
        }, 200, () => {
          this.$menu.addClass('hide');
        });

        $('body')
          .unbind('touchmove')
          .removeClass('stop-scrolling');
      } else {
        this.$menuContainer.popover('hide');
      }
    }
  }
}

export default Menu;
