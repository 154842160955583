/* eslint-disable import/no-extraneous-dependencies */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import $ from 'jquery';
// TODO: remove this after removing requireManager
window.jQuery = $;
window.$ = $;

import Rails from 'rails-ujs';

Rails.start();

import lozad from 'lozad';

import '~/vendor/assets/javascripts/jquery.cookie-manager';

// TODO: remove requireManager
import '~/app/assets/javascripts/requireable';

// TODO: remove this after map all pages that need demand_login
import './utils/demand_login';

//------------------
// Lazy Load for Images
//------------------

function lazyLoaded(el) {
  const $el = $(el);
  const $elParent = $el.parent();

  $el.addClass('lazy-load__image--loaded');
  if ($elParent.hasClass('lazy-load__placeholder')) {
    $elParent.attr('class', 'lazy-load__placeholder-fix');
  }
}

function loadNewImages(imgLazyloadObserver) {
  try {
    imgLazyloadObserver.observe(); // don't break if old browsers
  } catch (error) {
    // do nothing
  }
}

const imgLazyloadObserver = lozad('.js-lazy', {
  load: (el) => {
    // eslint-disable-next-line no-param-reassign
    el.onload = function load() {
      this.classList.remove('js-lazy');
      lazyLoaded(this);
    };

    // eslint-disable-next-line no-param-reassign
    el.onerror = function error() {
      lazyLoaded(this);
    };

    // necessary, because defining load function we override the original one
    // eslint-disable-next-line no-param-reassign
    el.src = el.dataset.src;
  },
});

loadNewImages(imgLazyloadObserver);

$(document).ajaxComplete(() => loadNewImages(imgLazyloadObserver));

// Fix lazy-load in msedge swiper
if (QB.isEdge) {
  $.each($('.swiper-slide .js-lazy'), (_, lazy) => imgLazyloadObserver.triggerLoad(lazy));
}
