import $ from 'jquery';

const breakpoints = {
  sm: 768,
  md: 992,
  lg: 1200,
};

class ScreenUtils {
  static size() {
    const w = $(window).width();
    if (w >= breakpoints.lg) {
      return 'lg';
    }
    if (w >= breakpoints.md) {
      return 'md';
    }
    if (w >= breakpoints.sm) {
      return 'sm';
    }
    return 'xs';
  }

  static isLg() {
    return ScreenUtils.size() === 'lg';
  }

  static isMd() {
    return ScreenUtils.size() === 'md';
  }

  static isSm() {
    return ScreenUtils.size() === 'sm';
  }

  static isXs() {
    return ScreenUtils.size() === 'xs';
  }

  static mdOrLarger() {
    return $(window).width() >= breakpoints.md;
  }

  static mdOrSmaller() {
    return $(window).width() < breakpoints.lg;
  }

  static smOrLarger() {
    return $(window).width() >= breakpoints.sm;
  }

  static smOrSmaller() {
    return $(window).width() < breakpoints.md;
  }

  // just to cover all possibilities
  static smOrMd() {
    const ref = ScreenUtils.size();
    return ref === 'md' || ref === 'sm';
  }
}

export default ScreenUtils;
