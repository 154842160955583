const prefix = 'qb';

class LocalStorageService {
  get store() {
    try {
      const originStore = window.localStorage.getItem(prefix);
      return originStore ? JSON.parse(originStore) : {};
    } catch (error) {
      return {};
    }
  }

  set store(value) {
    try {
      window.localStorage.setItem(prefix, JSON.stringify(value));
    } catch (error) {
      // User storage may be out of space. Do nothing.
    }
  }

  get(key) {
    return this.store[key];
  }

  set(key, value) {
    this.store = {
      ...this.store,
      [key]: value,
    };
  }

  remove(key) {
    const { [key]: value, ...store } = this.store;

    this.store = store;
  }
}

export default new LocalStorageService();
