export default function testCPF(strCPF) {
  // taken from http://www.receita.fazenda.gov.br/aplicacoes/atcta/cpf/funcoes.js
  const cpf = strCPF.replace(/[^\d]/gi, '');
  let i;
  let resto;
  let soma;
  soma = 0;

  if (cpf === '00000000000' || cpf === '') {
    return false;
  }

  i = 1;
  while (i <= 9) {
    soma += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    i += 1;
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== parseInt(cpf.substring(9, 10), 10)) {
    return false;
  }

  soma = 0;
  i = 1;
  while (i <= 10) {
    soma += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    i += 1;
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== parseInt(cpf.substring(10, 11), 10)) {
    return false;
  }

  return true;
}
