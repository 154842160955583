import '~/app/assets/javascripts/application/shared/header_help';
import '~/app/assets/javascripts/application/demand-login/loading-pre-checkout';
import { getLoginModalInstance } from '~/app/assets/javascripts/application/login_modal';

// This timeout forces to execute this after yield :js in test environment
setTimeout(() => {
  if (window.QB && window.QB.demandLogin) {
    if (window.QB.loadingLogin) {
      const login = getLoginModalInstance('.js-loading-login');

      login.show({
        block: true,
        hideCloseBtn: true,
        backdrop: false,
        onClose() {
          window.location.assign('/');
        },
        onSuccess() {
          window.location.reload();
        },
      });

      // remove bootstrap class with blocked scroll from modal
      $('body').removeClass('modal-open');
    } else {
      const loginModal = getLoginModalInstance();
      loginModal.show({
        block: true,
        hideCloseBtn: true,
        backdrop: 'static',
        onClose() {
          window.location.assign('/');
        },
      });
    }

    _require(['metrics'], () => {
      const properties = {
        page_path: window.location.pathname,
      };

      trackable.eventTracker.track(Metrics.Events.ViewedDemandLogin, properties);
    });
  }
});
