import 'bootstrap/js/modal';
import $ from 'jquery';
import appleService from '~/app/javascript/src/services/apple_service';
import facebookService from '~/app/javascript/src/services/facebook_service';
import loginService, * as loginServiceErrors from '~/app/javascript/src/services/login_service';
import validator from '~/app/javascript/src/utils/validator';

const defaultMainSelector = '.js-login-modal';

const handleOrigin = () => {
  const marketPlaceHosts = [
    'www.ead.com.br',
    'www.guiadacarreira.com.br',
    'www.mundovestibular.com.br',
  ];

  const { href, host } = window.location;
  const isFromNewMarketplaces = marketPlaceHosts.includes(host);

  if (isFromNewMarketplaces) {
    const defaultHost = 'querobolsa.com.br';
    const targetUrl = href.replace(host, defaultHost);

    window.location.assign(targetUrl);
  }
};
class LoginModal {
  constructor(mainSelector = defaultMainSelector) {
    this.successLogin = this.successLogin.bind(this);
    this.facebookSuccessLogin = this.facebookSuccessLogin.bind(this);
    this.successEvaluation = this.successEvaluation.bind(this);

    this.$loginModal = $(mainSelector);
    this.$registerPage = $('.js-login-register', this.$loginModal);
    this.$enterPage = $('.js-login-enter', this.$loginModal);
    this.$recoveryPage = $('.js-login-recovery', this.$loginModal);
    this.$alertMessages = $('.js-login-alert-message', this.$loginModal);
    this.$disableables = $('.js-disableable', this.$loginModal);
    this.$inputEmail = $('.js-input-email', this.$loginModal);
    this.$inputPassword = $('.js-input-password', this.$loginModal);
    this.$genericError = $('.js-generic-error', this.$loginModal);
    this.$invalidEmail = $('.js-invalid-email', this.$loginModal);
    this.$inputs = $('input', this.$loginModal);
    this.$goRegister = $('.js-login-go-register', this.$loginModal);
    this.$goEnter = $('.js-login-go-enter', this.$loginModal);
    this.$goRecovery = $('.js-login-go-recovery', this.$loginModal);
    this.$loginByFacebook = $('.js-login-by-facebook', this.$loginModal);
    this.$loginByApple = $('.js-login-by-apple', this.$loginModal);
    this.$registerForm = $('.js-register-form', this.$loginModal);
    this.$enterForm = $('.js-enter-form', this.$loginModal);
    this.$recoveryForm = $('.js-recovery-form', this.$loginModal);
    this.$registerBtn = $('.js-register-btn', this.$loginModal);
    this.$loginStepper = $('.js-login-stepper', this.$loginModal);
    this.$useFacebook = $('.js-use-facebook', this.$loginModal);
    this.$recoverPassword = $('.js-recover-password', this.$loginModal);
    this.$recoveryBtn = $('.js-login-recovery-btn', this.$loginModal);
    this.$accountSuspended = $('.js-account-suspended', this.$loginModal);
    this.$passwordTooShort = $('.js-password-too-short', this.$loginModal);
    this.$invalidLogin = $('.js-invalid-login', this.$loginModal);
    this.$recoverySuccess = $('.js-recovery-success', this.$loginModal);
    this.$emailNotFound = $('.js-email-not-found', this.$loginModal);
    this.$enterBtn = $('.js-enter-btn', this.$loginModal);
    this.$closeBtn = $('.js-close-btn', this.$loginModal);

    this.userEmail = '';

    this.$inputEmail.on('keyup', (e) => {
      this.userEmail = e.target.value;
      // Don't remove invalid email message if pressed key is ENTER
      if (e.which !== 13) {
        this.$invalidEmail.addClass('hidden');
      }
    });

    this.$inputs.on('change', () => this.$alertMessages.addClass('hidden'));
    this.$goRegister.on('click', this.renderRegisterPage.bind(this));
    this.$goEnter.on('click', this.renderEnterPage.bind(this));
    this.$goRecovery.on('click', this.renderRecoveryPage.bind(this));
    this.$loginByFacebook.on('click', this.loginFacebook.bind(this));
    this.$loginByApple.on('click', this.loginApple.bind(this));

    this.setFormSubmit(this.$registerForm, this.register.bind(this), { validatePassword: true });
    this.setFormSubmit(this.$enterForm, this.enter.bind(this));
    this.setFormSubmit(this.$recoveryForm, this.recover.bind(this));

    this.$loginModal.on('hidden.bs.modal', this.closeHandler.bind(this));
  }

  setFormSubmit($form, callback, { validatePassword } = {}) {
    return $form.on('submit', (e) => {
      e.preventDefault();
      this.userEmail = this.userEmail || this.$inputEmail.toArray().reduce((acc, el) => acc || el.value, '');
      const isEmailValid = validator.validate('email', this.userEmail);
      let isPasswordValid = true;

      if (validatePassword) {
        isPasswordValid = this.$inputPassword.filter(':visible').val().length >= 7;
      }

      if (!isEmailValid) {
        this.$invalidEmail.removeClass('hidden');
      }

      if (!isPasswordValid) {
        this.$passwordTooShort.removeClass('hidden');
      }

      if (isEmailValid && isPasswordValid) {
        this.$alertMessages.addClass('hidden');
        const data = $form.serialize();
        callback(data);
      }
    });
  }

  show(options = {
    block: false,
    closeOnSucess: false,
    recoveryEmail: null,
    hideCloseBtn: false,
    backdrop: 'static',
    onSuccess: null,
    onClose: null,
    shouldShowStepper: false,
  }) {
    this.enableAll();
    this.options = options;
    const {
      block,
      recoveryEmail,
      hideCloseBtn,
      backdrop,
      shouldShowStepper,
    } = options;
    const bootstrapModalOptions = block ? ({ show: true, backdrop, keyboard: false }) : 'show';
    this.$loginModal.modal(bootstrapModalOptions);

    if (hideCloseBtn) {
      this.$closeBtn.addClass('hidden');
    } else {
      this.$closeBtn.removeClass('hidden');
    }

    if (recoveryEmail) {
      this.userEmail = recoveryEmail;
      this.renderRecoveryPage();
    } else {
      this.renderRegisterPage();
    }

    if (shouldShowStepper) {
      this.showStepper();
    }

    return Promise.all([
      facebookService.loadSdk().then(() => {
        this.$loginByFacebook
          .removeClass('login-modal__facebook-btn--unload')
          .removeClass('login-modal__link--unload');
      }),
      appleService.loadSdk().then(() => {
        this.$loginByApple
          .removeClass('login-modal__apple-btn--unload')
          .removeClass('login-modal__link--unload');
      }),
    ]);
  }

  reset() {
    this.$registerPage.addClass('hidden');
    this.$enterPage.addClass('hidden');
    this.$recoveryPage.addClass('hidden');
    this.$alertMessages.addClass('hidden');
    this.$inputEmail.val(this.userEmail).trigger('keyup');
    this.$inputPassword.val('');
  }

  renderRegisterPage() {
    handleOrigin();
    this.reset();
    this.$registerPage.removeClass('hidden');

    _require(['metrics'], () => trackable.eventTracker.track(Metrics.Events.ViewedLoginRegisterForm));
  }

  renderEnterPage() {
    handleOrigin();
    this.reset();
    this.$enterPage.removeClass('hidden');

    _require(['metrics'], () => trackable.eventTracker.track(Metrics.Events.ViewedLoginForm));
  }

  renderRecoveryPage() {
    this.reset();
    this.$recoveryPage.removeClass('hidden');

    _require(['metrics'], () => trackable.eventTracker.track(Metrics.Events.ViewedLoginRecoverForm));
  }

  showStepper() {
    this.$loginStepper.removeClass('hidden');
  }

  disableAll() {
    this.$disableables.prop('disabled', true).attr('disabled', true);
  }

  enableAll() {
    this.$disableables.prop('disabled', false).attr('disabled', false);
  }

  register(loginData) {
    this.$registerBtn.addClass('btn-loading');
    this.disableAll();

    _require(['metrics'], () => trackable.eventTracker.track(Metrics.Events.ClickedRegisterUserButton));

    loginService.register(loginData).then(this.successLogin).catch((error) => {
      this.$registerBtn.removeClass('btn-loading');
      this.enableAll();

      let errorReason;

      switch (error.code) {
        case loginServiceErrors.ERROR_ALREADY_FACEBOOK_USER:
          this.$useFacebook.removeClass('hidden');
          errorReason = 'already_facebook_user';
          break;
        case loginServiceErrors.ERROR_INVALID_LOGIN:
          this.$recoverPassword.removeClass('hidden');
          errorReason = 'invalid_login';
          break;
        case loginServiceErrors.ERROR_ACCOUNT_SUSPENDED:
          this.$accountSuspended.removeClass('hidden');
          errorReason = 'account_suspended';
          break;
        case loginServiceErrors.ERROR_PASSWORD_TOO_SHORT:
          this.$passwordTooShort.removeClass('hidden');
          errorReason = 'password_too_short';
          break;
        default:
          this.$genericError.removeClass('hidden');
          errorReason = 'generic_error';
      }

      _require(['metrics'], () => trackable.eventTracker.track(Metrics.Events.ErrorFromRegisterUser, errorReason));
    });
  }

  enter(loginData) {
    this.$enterBtn.addClass('btn-loading');
    this.disableAll();

    _require(['metrics'], () => trackable.eventTracker.track(Metrics.Events.ClickedLoginButton));

    loginService.login(loginData).then(this.successLogin).catch((error) => {
      this.$enterBtn.removeClass('btn-loading');
      this.enableAll();

      let errorReason;

      switch (error.code) {
        case loginServiceErrors.ERROR_ACCOUNT_SUSPENDED:
          this.$accountSuspended.removeClass('hidden');
          errorReason = 'account_suspended';
          break;
        case loginServiceErrors.ERROR_INVALID_LOGIN:
          this.$invalidLogin.removeClass('hidden');
          errorReason = 'invalid_login';
          break;
        default:
          this.$genericError.removeClass('hidden');
          errorReason = 'generic_error';
      }

      _require(['metrics'], () => trackable.eventTracker.track(Metrics.Events.ErrorFromLogin, errorReason));
    });
  }

  loginFacebook() {
    this.$loginByFacebook.addClass('btn-loading');
    this.disableAll();

    _require(['metrics'], () => trackable.eventTracker.track(Metrics.Events.ClickedFacebookLoginButton));

    const eventProps = {
      has_authenticated: false,
    };

    const scope = 'email,user_birthday';
    loginService.fbLogin(scope).then(this.facebookSuccessLogin).catch((error) => {
      this.renderRecoveryPage();
      $('.js-emergence-alert').show();

      this.$loginByFacebook.removeClass('btn-loading');
      this.enableAll();
      const resp = error.facebookResponse;

      switch (error.code) {
        case loginServiceErrors.ERROR_FACEBOOK_NOT_AUTHORIZED:
          eventProps.reason = 'has_not_authorized';
          break;
        case loginServiceErrors.ERROR_FACEBOOK_BAD_RESPONSE:
          eventProps.reason = resp.status;
          Rollbar.error('[FB_LOGIN] Bad response', { status: resp.status, url: window.location.href });
          break;
        default:
          eventProps.reason = 'generic';
      }

      _require(['metrics'], () => trackable.eventTracker.track(Metrics.Events.ReturnedFromFacebookLogin, eventProps));
    });
  }

  loginApple() {
    this.$loginByApple.addClass('btn-loading');
    this.disableAll();

    _require(['metrics'], () => trackable.eventTracker.track(Metrics.Events.ClickedAppleLoginButton));

    loginService.appleLogin().then(this.successLogin).catch((error) => {
      const errorCode = error.error;

      if (errorCode !== 'popup_closed_by_user') {
        Rollbar.error(`[APPLE_LOGIN] Error ${errorCode}`, error);
      }

      this.$loginByApple.removeClass('btn-loading');
      this.enableAll();

      _require(['metrics'], () => trackable.eventTracker.track(Metrics.Events.ReturnedErrorFromAppleLogin, errorCode));
    });
  }

  recover(data) {
    this.$recoveryBtn.addClass('btn-loading');
    this.disableAll();

    _require(['metrics'], () => trackable.eventTracker.track(Metrics.Events.ClickedLoginRecoverButton));

    loginService.recover(data).then(() => {
      this.$recoverySuccess.removeClass('hidden');
      _require(['metrics'], () => trackable.eventTracker.track(Metrics.Events.ReturnedSuccessFromLoginRecover));
    }).catch((error) => {
      let errorReason;

      switch (error.code) {
        case loginServiceErrors.ERROR_ACCOUNT_SUSPENDED:
          this.$accountSuspended.removeClass('hidden');
          errorReason = 'account_suspended';
          break;
        case loginServiceErrors.ERROR_EMAIL_NOT_FOUND:
          this.$emailNotFound.removeClass('hidden');
          errorReason = 'email_not_found';
          break;
        default:
          this.$genericError.removeClass('hidden');
          errorReason = 'generic_error';
      }

      _require(['metrics'], () => trackable.eventTracker.track(Metrics.Events.ErrorFromLoginRecover, errorReason));
    }).then(() => {
      this.enableAll();
      this.$recoveryBtn.removeClass('btn-loading');
    });
  }

  facebookSuccessLogin() {
    switch (this.$loginByFacebook.attr('data-page')) {
      case 'evaluation':
        this.successEvaluation('/college_reviews/update_user_form');
        break;
      case 'fb-login':
        this.successEvaluation('/college_reviews/update_forms');
        break;
      default:
        this.successLogin();
    }
  }

  successEvaluation(url) {
    const actualUrl = new URL(window.location.href);
    const data = {
      form_id: actualUrl.searchParams.get('s'),
    };

    return $.ajax({
      url,
      type: 'POST',
      data,
      success(result) {
        window.dataLayer.push({
          reviewLogin: true,
          event: 'login_review',
        });
        window.location.href = result.url;
      },
      error() {
        window.location.reload();
      },
    });
  }

  successLogin() {
    if (this.options && this.options.onSuccess) {
      this.options.onSuccess();
      if (this.options.closeOnSucess) {
        this.$closeBtn.click();
      }
    } else {
      document.location.reload();
    }
  }

  closeHandler() {
    if (this.options && this.options.onClose) {
      this.options.onClose();
    }
  }

  checkForFragment() {
    if ((window.location.hash.indexOf('login') !== -1) && !window.QB.isUserSignedIn) {
      this.show();
    }
  }
}

const loginModalInstances = {};

export const getLoginModalInstance = (mainSelector = defaultMainSelector) => {
  let loginModal = loginModalInstances[mainSelector];

  if (loginModal) return loginModal;

  loginModal = new LoginModal(mainSelector);
  loginModalInstances[mainSelector] = loginModal;

  return loginModal;
};

export default LoginModal;
