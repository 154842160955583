/* eslint-disable */

import $ from 'jquery';
import AppleService from '~/app/javascript/src/services/apple_service';
import GoogleService from '~/app/javascript/src/services/google_service';
import FacebookService from './facebook_service';

export const ERROR_ACCOUNT_SUSPENDED = 0;
export const ERROR_ALREADY_FACEBOOK_USER = 1;
export const ERROR_ALREADY_GOOGLE_USER = 2;
export const ERROR_EMAIL_NOT_FOUND = 3;
export const ERROR_FACEBOOK_BAD_RESPONSE = 4;
export const ERROR_FACEBOOK_NOT_AUTHORIZED = 5;
export const ERROR_GENERIC = 6;
export const ERROR_INVALID_LOGIN = 7;
export const ERROR_PASSWORD_TOO_SHORT = 8;

const SERVER_ERROR_GENERIC = 0; // eslint-disable-line
const SERVER_ERROR_BAD_REQUEST = 1; // eslint-disable-line
const SERVER_ERROR_WRONG_CREDENTIALS = 2; // eslint-disable-line
const SERVER_ERROR_ACCOUNT_SUSPENDED = 3;
const SERVER_ERROR_PASSWORD_TOO_SHORT = 8;
const SERVER_ERROR_INEXISTENT_USER = 4; // eslint-disable-line
const TENANT_CODE = '6ce6d172-bc9c-4fbf-9b43-7b62ebd64601';

const authHeaders = {
  'system': TENANT_CODE,
  'Tenant-Code': TENANT_CODE,
};

class LoginService {
  static register(loginData) {
    return new Promise((resolve, reject) => {
      $.post({
        url: '/api/qb/v1/auth/register',
        headers: authHeaders,
        data: loginData,
      }).then(resolve, (error) => {
        const message = error.responseText.toLowerCase();
        const isFacebookLogin = message.includes('conta já vinculada, entre com o facebook');
        const isGoogleLogin = message.includes('conta já vinculada, entre com o google');

        if (isFacebookLogin) {
          return reject({
            error,
            code: ERROR_ALREADY_FACEBOOK_USER,
          });
        }

        if (isGoogleLogin) {
          return reject({
            error,
            code: ERROR_ALREADY_GOOGLE_USER,
          });
        }

        switch (error.status) {
          case 401:
          case 403: {
            const errorCode = error.responseJSON.error_code;

            switch (errorCode) {
              case SERVER_ERROR_ACCOUNT_SUSPENDED: {
                return reject({
                  error,
                  code: ERROR_ACCOUNT_SUSPENDED,
                });
              }
              case SERVER_ERROR_PASSWORD_TOO_SHORT: {
                return reject({
                  error,
                  code: ERROR_PASSWORD_TOO_SHORT,
                });
              }
              default: {
                return reject({
                  error,
                  code: ERROR_INVALID_LOGIN,
                });
              }
            }
          }
          default: {
            return reject({
              error,
              code: ERROR_GENERIC,
            });
          }
        }
      });
    });
  }

  static login(loginData) {
    return new Promise((resolve, reject) => {
      $.post({
        url: '/api/qb/v1/auth/login',
        headers: authHeaders,
        data: loginData,
      }).then(resolve, (error) => {
        switch (error.status) {
          case 401:
          case 403: {
            const errorCode = error.responseJSON.error_code;

            switch (errorCode) {
              case SERVER_ERROR_ACCOUNT_SUSPENDED: {
                return reject({
                  error,
                  code: ERROR_ACCOUNT_SUSPENDED,
                });
              }
              default: {
                return reject({
                  error,
                  code: ERROR_INVALID_LOGIN,
                });
              }
            }
          }
          default: {
            return reject({
              error,
              code: ERROR_GENERIC,
            });
          }
        }
      });
    });
  }

  static recover(data) {
    return new Promise((resolve, reject) => {
      $.post({
        url: '/api/qb/v1/auth/recovery',
        headers: authHeaders,
        data,
      }).then(resolve, (error) => {
        switch (error.status) {
          case 403: {
            const message = error.responseText.toLowerCase();
            const isAccountSuspended = message.includes('a conta foi suspensa, entre em contato conosco');

            if (isAccountSuspended) {
              return reject({
                error,
                code: ERROR_ACCOUNT_SUSPENDED,
              });
            }

            return reject({
              error,
              code: ERROR_EMAIL_NOT_FOUND,
            });
          }
          default: {
            return reject({
              error,
              code: ERROR_GENERIC,
            });
          }
        }
      });
    });
  }

  static fbLogin(scope) {
    return FacebookService.loadSdk().then((FB) => {
      return new Promise((resolve, reject) => {
        FB.login((resp) => {
          const { authResponse, status } = resp;

          switch (status) {
            case 'connected': {
              const params = {
                uid: authResponse.userID,
                code: authResponse.accessToken,
              };
              return $.get({
                url: '/api/qb/v1/auth/fb-login',
                headers: authHeaders,
                data: params,
              }).done(resolve).fail((error) => {
                reject({
                  error,
                  code: ERROR_GENERIC,
                });
              });
            }
            case 'authorization_expired':
            case 'not_authorized': {
              return reject({
                code: ERROR_FACEBOOK_NOT_AUTHORIZED,
                facebookResponse: resp,
              });
            }
            case 'unknown': {
              return reject({
                code: ERROR_FACEBOOK_BAD_RESPONSE,
                facebookResponse: resp,
              });
            }
            default: {
              return reject({
                code: ERROR_GENERIC,
                facebookResponse: resp,
              });
            }
          }
        }, { scope });
      });
    });
  }

  static googleLogin() {
    return GoogleService.loadSdk().then((gapi) => {
      // TODO: Tratar casos de erro
      const googleAuth = gapi.auth2.getAuthInstance();
      return googleAuth.signIn().then((googleUser) => {
        return new Promise((resolve, reject) => {
          $.post({
            url: '/api/qb/v1/auth/google-login',
            headers: authHeaders,
            data: {
              token: googleUser.getAuthResponse().id_token,
            },
          }).done(resolve).fail(reject);
        });
      });
    });
  }

  static appleLogin() {
    return AppleService.loadSdk().then((aapi) => {
      return aapi.auth.signIn().then((appleData) => {
        return new Promise((resolve, reject) => {
          $.post({
            url: '/api/qb/v1/auth/apple-login',
            headers: authHeaders,
            data: {
              token: this.get(appleData, ['authorization', 'id_token']),
              user_name: this.get(appleData, ['user', 'name', 'firstName']),
            },
          }).done(resolve).fail(reject);
        });
      }).catch(err => Rollbar.warn(`[APPLE_SIGNIN] ${err.error}`, err));
    }).catch(err => Rollbar.warn(`[APPLE_SERVICE] ${err.error}`, err));
  }

  static get(obj, path, defValue) {
    if (!path) return undefined;

    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);

    const result = pathArray.reduce(
      (prevObj, key) => prevObj && prevObj[key],
      obj
    );

    return result || defValue;
  }
}

export default LoginService;
