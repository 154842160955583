import $ from 'jquery';
import 'bootstrap/js/modal';
import { getLoginModalInstance } from 'application/login_modal';
import Menu from 'application/shared/menu';
import localStorageService from '~/app/javascript/src/services/local_storage_service';

const CONSTANTS = {
  undergraduate: 'Graduação',
  postgraduate: 'Pós-graduação',
};

function switchPhoneNumberByLevel(level) {
  const root = $('#body-querobolsa');
  const $gradPhoneNumberHeader = $('.js-level-grad');
  const $posPhoneNumberHeader = $('.js-level-pos-grad');

  if (level === CONSTANTS.undergraduate) {
    $gradPhoneNumberHeader.fadeIn('fast');
    $posPhoneNumberHeader.hide();
    root.attr('data-theme', 'undergraduate');
  } else {
    $gradPhoneNumberHeader.hide();
    $posPhoneNumberHeader.fadeIn('fast');
    root.attr('data-theme', 'postgraduate');
  }
}

$(window).on('load', () => {
  const defaultSimplifiedLevel = localStorageService.get('defaultSimplifiedLevel');

  if (defaultSimplifiedLevel) {
    switchPhoneNumberByLevel(defaultSimplifiedLevel);
  }
});

_require([
  'bootstrap/popover',
], () => {
  const menu = new Menu(QB.rebrand === 'guia_do_estudante');

  $('.js-user-menu-toggle').on('click', () => {
    if (menu.$menu.length) {
      menu.showMenu();
    } else {
      const loginModal = getLoginModalInstance();
      loginModal.show();
    }
  });
});
